@font-face {
    font-family: 'Regular';
    src: url('../assets/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'SemiBold';
    src: url('../assets/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Bold';
    src: url('../assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
    font-style: normal;
}


@font-face {
    font-family: 'TitanOneRegular';
    src: url('../assets/fonts/TitanOne-Regular.ttf') format('truetype');
    font-style: normal;
}


.Titre-simple {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 36px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;
}


.Titre-simple-center {
    
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 30px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #474747;
}



.Titre-degrade {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 36px;
    margin: 0;
    padding: 0;
    text-align: left;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}




.Titre-menu-simple {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;
}




.Titre-menu-simple-active {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}


.Titre-menu-simple-organisation {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;
}


.Titre-menu-simple-organisation-20 {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;
}


.Titre-menu-simple-active-organisation {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: left;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}


.Titre-menu-simple-red {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: rgb(160, 0, 0);
}


.stat-title{
    all: unset;
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;
}

.stat-change.positive {
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    color: #10b981;
}
  
.stat-change.negative {
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    color: #ef4444;
}

.sousTitre1-simple {
    font-family: 'Regular', sans-serif;
    font-size: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;

}



.sousTitre2-simple {
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;

}

.sousTitre2-simple-left {
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;

}

.sousTitre2-simple-degrade-18 {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;

}


.sousTitre2-simple-degrade-18-pointer {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
    cursor: pointer; 
}

.sousTitre2-simple-18 {
    font-family: 'Bold', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;

}

.sousTitre2-simple-center {
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #474747;
    max-width: 800px;

}

.sousTitre2-simple-degrade-center {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}

.sousTitre2-simple-degrade-left-20 {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: left;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}


.sousTitre2-simple-white-20 {
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #fff;
}

.sousTitre2-simple-white {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #fff;
}

.sousTitre2-simple-gray {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #8a8a8a;
}

.sousTitre2-simple-degrade {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}

.changeColorStyle{
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    font-weight: 600;
    color: transparent; 
    display: inline;
}

.sousTitre2-simple-degrade-left {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 25px;
    margin: 0;
    padding: 0;
    text-align: left;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}




.description-simple {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    max-width: 450px;
    text-align: left;
    color: #474747;
    white-space: pre-line;

}

.description-simple-center {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    max-width: 550px;
    text-align: center;
    color: #474747;
    white-space: pre-line;

}



.description-simple-non-actif{
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    max-width: 550px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #474747;
    white-space: pre-line;

}

.description-simple-no-color {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    max-width: 550px;
    margin: 0;
    padding: 0;
    text-align: left;
    white-space: pre-line;

}

.citation-simple {
    font-family: 'Bold', sans-serif;
    font-size: 30px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #fff;

}


/*Exemple de bordure dégradé arrondi */
.box-border-degrade-round {
    border: double 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.span1 {
    display: block;
    height: 100px
}

.span2 {
    display: block;
    height: 30px
}

.span3 {
    display: block;
    height: 20px
}

.span4 {
    display: block;
    height: 100px
}

.span5 {
    display: block;
    height: 80px
}


.Titre {
    font-family: 'Regular', sans-serif;
    font-size: 36px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: black;
}

.sousTitre1 {
    font-family: 'Regular', sans-serif;
    font-size: 24px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: black;

}

.sousTitre2 {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    color: black;
    margin: 0;
    padding: 0;
    text-align: left;
}

.sousTitre2Center {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    color: black;
    margin: 0;
    padding: 0;
    text-align: center;
}

.sousTitre2red {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    color: red;
    margin: 0;
    padding: 0;
    text-align: left;
}

.sousTitre2Gris {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    color: dimgrey;
    margin: 0;
    padding: 0;
    text-align: left;
}

.sousTitre2Gris-center {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    color: dimgrey;
    margin: 0;
    padding: 0;
    text-align: center;
}


.description {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: left;
    max-width: 450px;
    color: black;
}

.description-demande-avis {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: left;
    color: black;
}

.description-demande-avis-click{
    all: unset;
    cursor: pointer;
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}

.div-gestion-avis{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;

}

.button-avis-gestion-update {
    
    display: flex;
    align-items: center;
    gap: 10px;

    border: 1px solid #8A2BE2; 
    color: #8A2BE2;
    border-radius: 10px;
    cursor: pointer; 
    margin-top: 5px;
    padding: 8px 15px;
    transition: background-color 0.3s ease;
    
}

.button-avis-gestion-update:hover {
    
    background-color: #8A2BE2; 
    color: white;

    transition: background-color 0.3s ease;
}





.button-avis-gestion-delete {
    display: flex;         
    justify-content: center;

    border: 1px solid #ac0000;
    color: #ac0000;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 5px;
    padding: 10px;
    text-align: center;

    max-width: 398px;
    max-height: 41px;

    transition: background-color 0.3s ease;
}




.button-avis-gestion-delete:hover {

    background-color: #ac0000; 
    color: white;

    transition: background-color 0.3s ease;

}

.description-demande-avis-nonclick{
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}

.description-orange {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: left;
    max-width: 450px;
    color: #EC7100;
}

.description-vert {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: left;
    max-width: 450px;
    color: green;
}

.descriptionConfidentiel {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: left;
    max-width: 450px;
    min-width: 427px;
    background-color: rgba(102, 102, 102, 0.2);
    border-radius: 15px;
    padding: 10px;
    color: black;
}


.container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.container-content-left {
    /* display: flex;
    justify-content: left;
    width: 100%; */
}

.user-list-content-left  {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 0;
    list-style-type: none;
    justify-content: left;
}


.gestion-prestation-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /* margin-top: 20px; */
    /* border: 1px solid red; */
    max-width: 935px;
}

.align-content-container{
    display: flex;
    flex-direction: column;
    align-items: left;
}





/*Gestion des fieldset*/
.fields-cpo-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    background-color: #F8FAFD;
    border-radius: 15px;
}

.div-titre-cpo-fields
{
    margin-top: 15px;
    margin-left: 15px;
}

.fieldset-title {
    grid-column: 1 / -1; 
    margin-bottom: 5px;
}

.fieldset-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-left: 30px;
    margin-top: 25px;
    padding: 5px 15px;
    border-left: 3px solid transparent;
    border-image: linear-gradient(5deg, #FFA500, #8A2BE2);
    border-image-slice: 1;
}


.fieldset-section-parametrage {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-left: 30px;
    margin-top: 25px;
    padding: 5px 15px;
    border-left: 3px solid transparent;
    border-image: linear-gradient(5deg, #FFA500, #8A2BE2);
    border-image-slice: 1;
}




.custom-field-container {
    
    margin-left: 15px;

}







.user-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 0;
    list-style-type: none;
    justify-content: center;
}

.user-item {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    max-width: 450px;
    box-sizing: border-box;
    
}


.user-item-experience {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    text-align: left;
    max-width: 450px;
    box-sizing: border-box;
    /* 
    overflow: hidden;
    opacity: 0; 
    transition: opacity 1s ease, transform 1s ease; 
    */
}

.user-info {
    display: flex;
    align-items: center;
    min-width: 350px;
    max-width: 350px;
    gap: 10px;
    margin-bottom: 15px;
}

.user-info-details {
    display: flex;
    align-items: center;
    min-width: 350px;
    max-width: 350px;
    gap: 10px;
}

.image-profil {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    height: auto;
    display: block;
    border-radius: 10px;
}

.user-details {
    display: flex;
    flex-direction: column;
}

.image-publication {
    max-width: 100%;
    max-height: 450px;
    min-height: 300px;
    min-width: 100%;
    display: block;
    border-radius: 15px;
}

.image-publication-details {
    /* max-width: 100%; */
    max-height: 450px;
    min-height: 300px;
    min-width: 100%;
    display: block;
    border-radius: 15px;
}

.image-publication-paiement {
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 15px;
    border: 0px solid red;
}

.publication-container-paiement  {
    display: flex;
    align-items: center; /* Aligne verticalement l'image et le texte */
}

.publication-text-paiement  {
    margin-left: 15px; /* Espace entre l'image et le texte */
    font-size: 16px; /* Ajustez la taille de la police comme vous le souhaitez */
    color: #333; /* Couleur du texte, ajustez selon vos besoins */
}



.organisation-image-container {
    position: relative; 
    display: inline-block; 
    cursor: pointer; 
}
  
.organisation-image-publication {
    /* max-width: 100%; */
    max-height: 450px;
    min-height: 300px;
    min-width: 100%;
    display: block;
    border-radius: 15px;
    transition: opacity 0.3s ease; 
}
  
.organisation-overlay {
    position: absolute; 
    top: 0;
    left: 0; 
    right: 0; 
    bottom: 0; 
    background-color: rgba(0, 0, 0, 0.5); 
    color: white; 
    display: flex; 
    align-items: center;
    justify-content: center;
    opacity: 0; 
    border-radius: 15px;
    transition: opacity 0.3s ease; 
}
  

.organisation-image-container:hover .organisation-overlay {
    opacity: 1; 
    transition: opacity 0.3s ease; 
}
  
.organisation-image-container:hover .organisation-image-publication {
    opacity: 0.7; 
    transition: opacity 0.3s ease; 
}
  
  

  
.action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-actions {
    display: flex;
    align-items: center;
    justify-content: 'center';
    gap: 10px;
}

.button {
    padding: 10px;
    margin: 5px 0; /* Space between buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
}

.likeButton{
    display: inline-block;
    background-color: #ffffff;
    color: #fff;
    text-decoration: none;
    border: 0px solid black;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    align-items: center;
}


.likeButton img {
    transition: transform 0.2s ease-in-out;
}

/* Effet au clic */
.likeButton:active img {
    transform: scale(0.8); /* Réduction lors du clic */
}

/* Animation de pop lors du like/unlike */
.likeButton.liked img {
    animation: popRelease 0.4s ease-in-out;
}

@keyframes popRelease {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3); 
    }
    100% {
        transform: scale(1); 
    }
}



.shareButton{
    display: inline-block;
    background-color: #ffffff;
    color: #fff;
    text-decoration: none;
    border: 0px solid black;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    align-items: center;
}

.buttonIconeDroite {
    display: inline-flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    border: 0px solid black;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 0px 0px;
}

.buttonIconeGauche {
    all: unset;
    display: inline-flex;
    align-items: center;
    /* background-color: #ffffff; */
    color: #000000;
    text-decoration: none;
    border: 0px solid black;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
}

.overlay-option {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
}



.overlay-participant {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}
  
.overlay-participant.visible {
    opacity: 1;
    visibility: visible;
}


.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.input {
    width: 80%;
    padding: 8px;
    margin-right: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
}

.input-container-left {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-bottom: 10px;
    height: 45px; 
}


.input-container-left-cpo {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-bottom: 5px;
    margin-left: 10px;
}


.form-input-left-s {
    width: 0px !important; 
    height: 45px !important;
    visibility: hidden;
}

.react-tel-input{
    width: 30px !important; 
}

.flag-dropdown {
    background-color: #fff !important;
    border-top-left-radius: 10px !important;  
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0 !important;     
    border-bottom-right-radius: 0 !important; 
}


.custom-select{
    background-color: #fff !important;
    border-top-left-radius: 10px !important;   
    border-bottom-left-radius: 10px !important; 
    border-top-right-radius: 0 !important;      
    border-bottom-right-radius: 0 !important; 
}


.phone-container {
    display: flex;             
    gap: 9px;               
}

.input-container-left-telephone {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    margin-right: 29px;
    max-width: 360px;
    text-align: left;
    cursor: text ;
    height: 0px;
}

.form-input-left-telephone {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    padding: 11px;
    margin-right: 5px;
    border: 1px solid #ccc;
    background-color: #fff !important;
    border-top-left-radius: 0 !important;   
    border-bottom-left-radius: 0 !important; 
    border-top-right-radius: 10px !important;      
    border-bottom-right-radius: 10px !important;
    text-align: left;
    cursor: text ;
}
  

.input-left {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 80%;
    max-width: 400px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: left;
    cursor: text;
}

.copy-button {
    padding: 8px 12px;
    background-color: #4A35AB;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.copy-success {
    color: #28a745;
    margin-top: 10px;
    height: 18px;
}

.close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4A35AB;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
}

.tag {
    padding: 6px 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 90%;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
}

.modal-option {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 90%;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
}


.modal-participants {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 900px;
    width: 90%;
    max-height: 90vh;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: auto;
}

.modal-avis {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 900px;
    width: 90%;
    max-height: 90vh;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: auto;
}





.button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}



.login-button {
    background-color: #4A35AB;
    color: white;
    border-radius: 15px;
}

.signup-button {
    background-color: #fff;
    color: #4A35AB;
    border: 1px solid #4A35AB;
    border-radius: 15px;
}

.list-details-publication {
    max-width: 450px;
    min-width: 450px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    border-radius: 10px;
    justify-content: flex-start;
}

.list-item-details-publication {
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 10px;
    max-width: 450px;
    min-width: 450px;
    box-sizing: border-box;
}

.icon {
    display: flex;
    align-items: center; 
    justify-content: center;
}

.textRight{

    margin-left: auto; 
    text-align: right;

}

.div-participant-prestataire {
    display: flex;
    gap: 30px;
    max-width: 100%;
}

.participant {
    background-color: #F8FAFD;
    min-width: 420px;
    max-width: 420px;
    margin-left: 0px;
    padding: 15px;
    border-radius: 15px;
}


.liste-participant{
    display: flex;
    flex-wrap: wrap;
    gap: 25px
}


.div-liste-participant-modal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
}

.div-liste-avis-modal {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 25px;
}

.user-info-details-participant {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    min-width: 180px;
    max-width: 180px;
    gap: 10px;
}

.prestataire {
    background-color: #F8FAFD;
    min-width: 430px;
    max-width: 430px;
    margin-right: 0px;
    padding: 15px;
    border-radius: 15px;
}

.titre-alignement-separation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.titre-alignement-separation-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* justify-content: flex-start; */
}


.contact-button-details {

    display: flex;
    justify-content: center;
    padding: 8px;
   
    border: double 2px transparent;
    border-radius: 12px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;


}



.button:hover {
    opacity: 0.9;
}



/* Style menu */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    /* background-color: #333; */
    color: #000000;
    position: relative;
}


.nav-center {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
}

.nav-list-compte {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.nav-link-compte {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    gap: 10px
}


.nav-item .nav-link {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
}

.nav-link-left {
    padding: 0;
    text-align: left;
}

.nav-right {
    margin-left: auto;
}

.logo{
    margin-top: 5px;
}

.logo-left{
    margin-top: 5px;
}

.menu-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(136, 136, 136, 0.4);
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.menu-mobile.open {
    display: block;
    transform: translateX(0);
}

.menu-close {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 20px;
}

.menu-toggle {
    display: none;
    margin-top: 5px;
    background-color:#fff ;
    border: 0px solid #fff;
    cursor: pointer;
}

.menu-list-mobile {
    list-style: none;
    padding: 0;
    margin: 0;
    /* height: 60vh; */
    /* border: 1px solid black;  */
}

.menu-list-mobile .menu-item {
    margin: 25px 0;
}

.menu-list-mobile .menu-link {
    text-decoration: none;
    color: #fff;
    font-size: 18px;

}

.deconnexion-item {
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 90%;
    background-color: #fff;
    text-align: left;
    /* border-top: 1px solid #444; */
    margin-left: 20px;
    cursor: pointer;
}


.deconnexion-item .nav-link {
    width: 90%;
    background: none;
    border: none;
    color: #fff;
    font-size: 18px;
    color: red;
    cursor: pointer;
}




.deconnexion-item-menu-utilisateur {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 90%;
    background-color: #fff;
    text-align: left;
    margin-left: 15px;
    cursor: pointer;
    border: none;
    color: #fff;
    font-size: 18px;
    color: red;
    cursor: pointer;

}




.bottom-nav-menu-organisation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    height: 60px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: none;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
}

.nav-item-menu-organisation {
    display: none;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-family: Arial, sans-serif;
    position: relative;
}

.nav-plus-menu-organisation {
    position: relative;
    bottom: 20px;
    width: 45px;
    height: 45px;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    border-radius: 50%;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
}

.nav-plus-menu-organisation:hover {
    transform: translateY(-5px);
}



.nav-label-menu-organisation {
    font-size: 12px;
    margin-top: 4px;
}





.content {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}




.organisations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}


.organisations-title {
    font-size: 2em;
    margin-bottom: 0px;
    color: #333;
    text-align: center;
}


.organisations-content {
    margin-bottom: 50px;
    margin-top: 5px;
    font-size: 1.2em;
    color: #666;
    text-align: center;
}

.organisations-loading, .organisations-error {
    color: #d9534f;
    font-weight: bold;
    text-align: center;
}


.organisations-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 0;
    margin: 0;
    list-style-type: none;
    justify-content: center;
    min-width: 90%;
    
}



.organisation-item {
    position: relative;
    background-color: #F8FAFD;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    min-width: 450px;
    max-width: 500px;
    border-bottom: 0px solid gray;
    padding: 20px;
    overflow: hidden;
    cursor: pointer;
}

.organisation-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.organisation-item:hover::before {
    opacity: 1;
}

.organisation-item:hover .Titre-menu-simple {
    color: #ffffff !important;
}

.organisation-item > * {
    position: relative;
    z-index: 2;
}

.organisation-item:hover {
    color: #ffffff !important;
}




.organisation-logo img {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    border-radius: 10px;
    object-fit: cover;
    display: flex;
    align-items: center;
}

.organisation-details {
    padding-left: 10px;
    padding-right: 50px;
}


.organisation-detail-label {
    font-weight: bold;
    color: #333;
}

.organisation-button {
    margin-left: auto;
    display: flex;
    align-items: center;
}




.profile-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.profile-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.profile-content {
    margin-bottom: 20px;
    font-size: 16px;
}

.profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.profile-details {
    display: flex;
    flex-direction: column;
}

.profile-name-container {
    display: flex;
    align-items: center;
}

.profile-label {
    font-weight: bold;
    margin-right: 10px;
}

.profile-value {
    margin-right: 20px;
}

.profile-edit-button {
    background-color: #4A35AB;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.profile-form-group {
    margin-bottom: 20px;
}

.profile-input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.profile-nav-link {
    color: #4A35AB;
    text-decoration: none;
    font-size: 16px;
}

.no-link{
    margin-left: 10px;
    color: #474747;
    text-decoration: none; 
}

.no-link-simple{
    text-decoration: none; 
}




.profile-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.profile-nav-item-modification{
    text-decoration: none;
    border: 1px solid #4A35AB;
    color: #4A35AB;
    padding: 8px;
    border-radius: 8px;
}

.profile-nav-item-modification:hover{
    text-decoration: none;
    color: #fff;
    border: 1px solid #4A35AB;
    background-color: #4A35AB;
    transition: background-color 0.4s ease;
}


.profile-nav-item {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    color: #4A35AB;
    font-size: 16px;
    width: 200px;

    box-sizing: border-box;
}

.profile-nav-item:hover {
    background-color: #e9ecef;
    border-color: #ccc;
}

.profile-icon {
    margin-right: 10px;
}



  

/* Gestion de la barre de navigation */
.top-navbar-organisation {
    background-color: #F8FAFD;
}
  
.top-navbar-container-organisation {
    margin-left: 30px;
    height: 60px;
    display: flex;
    align-items: center;
}

.top-navbar-right-organisation {
    display: flex;
    align-items: center;
    margin-left: auto; 
    margin-right: 20px;
}

.nav-list-compte {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    /* margin-left: 20px; */
}



/* Gestion du menu organisation */
.menuOrganisation-createPublication-container {
    display: flex;
    margin-top: auto;
    gap: 10px;
}

.menuOrganisation-sidebar {
    width: 60px;
    background-color: #F8FAFD;
    padding: 15px;
    border-radius: 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}


.menuOrganisation-sidebar:hover {
    width: 180px;
}


.menuOrganisation-logo a {
    display: flex;
    align-items: center;
    padding-left: 15px;
}
 
.menuOrganisation-logo-image {
    width: 30px;
}
 
.menuOrganisation-logo-texte {
    display: none;
    height: 20px;
}
 

.menuOrganisation-sidebar:hover .menuOrganisation-logo-texte {
    display: block;
    margin-left: 10px;
}
 
.menuOrganisation-sidebar:hover .menuOrganisation-logo a {
    padding-left: 0px;
    padding-right: 0px;
}

.menuOrganisation-sidebar:hover .menuOrganisation-logo {
    transform: scale(1.05); 
}

.menuOrganisation-sidebarList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    margin-top: 35px;
}


.line {
    width: 70%;
    height: 1px;
    background-color: #777777;
    margin: 30px auto; 
}



.menuOrganisation-sidebar:hover .line {
    width: 100%;
}


/* .menuOrganisation-sidebar:hover .menuOrganisation-sidebarList{
    padding-left: 10px;
} */

.menuOrganisation-sidebarItem {
    margin-bottom: 40px;
    white-space: nowrap;
    transition: transform 0.2s ease;
}

.menuOrganisation-sidebarItem:hover {
    transform: translateX(5px); 
}


.select-organisation-topbar {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease;
}


.menuOrganisation-sidebarLink {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease;
}

.menuOrganisation-sidebar:hover .menuOrganisation-sidebarLink {
    justify-content: flex-start;
}


.menuOrganisation-sidebarLink h2,
.menuOrganisation-sidebarLink p {
    display: none;
}

.menuOrganisation-sidebar:hover .menuOrganisation-sidebarLink h2,
.menuOrganisation-sidebar:hover .menuOrganisation-sidebarLink p {
    display: block;
    animation: fadeIn 0.3s ease forwards;
}


.menuOrganisation-createPublication-container {
    margin-top: auto;
    justify-content: center;
    margin-bottom: 5px;
    transition: all 0.3s ease;
}

.menuOrganisation-sidebarLink-createPublication {
    text-decoration: none;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    transition: transform 0.3s ease;
}


.menuOrganisation-sidebarLink-createPublication-icone {
    text-decoration: none;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    transition: transform 0.3s ease;
}

.menuOrganisation-sidebarLink-createPublication-icone:hover {
    transform: scale(1.1); 
}

.menuOrganisation-createPublication {
    flex: 1;
    text-align: center;
    color: white;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    display: none;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 15px;
    transition: transform 0.3s ease;
}

.menuOrganisation-createPublication:hover {
    transform: scale(1.02); 
}

.menuOrganisation-sidebar:hover .menuOrganisation-sidebarLink-createPublication-icone {
    display: none;
}

.menuOrganisation-sidebar:hover .menuOrganisation-createPublication {
    display: flex;
}

.menuOrganisation-content {
    flex: 1;
    margin-left: 100px;
    padding: 20px;
    border-radius: 20px;
    overflow-y: auto;
    transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.menuOrganisation-sidebar:hover + .menuOrganisation-content {
    margin-left: 220px;
}






@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.createPublication-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.createPublication-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.createPublication-description {
    font-size: 16px;
    margin-bottom: 20px;
}

.createPublication-dropdownContainer {
    margin-bottom: 15px;
    max-width: 300px;
}

.dropdownContainer {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-bottom: 10px;
}

.dropdown-form-left {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 420px;
    margin-right: 5px;
    border-radius: 10px;
    text-align: left;
    cursor: text ;
    /* border: 1px solid red; */
}


.createPublication-fileInput {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.createPublication-fileInput-container {
    border: 2px dashed #747474;
    border-radius: 15px;
    max-width: 335px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    align-items: center;    
    text-align: center; 
    cursor: pointer;
}

.createPublication-fileInput-container:hover {
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    border: 2px solid white;
}
.createPublication-fileInput-label {
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 95%;
    color: #747474;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}

.createPublication-fileInput-label:hover {
    color: white;
}

.createPublication-fileRemoveButton {
    all: unset;
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    margin-bottom: 15px;
    border: none;
    border-radius: 20px;
    color: #dc3545;
    background-color: #ffffff;
    border: 2px solid white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.createPublication-fileRemoveButton:hover {
    background-color: #dc3545;
    color: #ffffff;
    border: 2px solid white;
}


.createPublication-contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.createPublication-imageContainer {
    flex: 1;
    max-width: 450px;
    width: 450px;
    height: 450px;
    overflow: hidden;
    margin-right: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.createPublication-croppedImage {
    min-width: 450px;
    min-height: 450px;
    max-width: 450px;
    max-height: 450px;
    object-fit: cover;
    border-radius: 15px;
}

.createPublication-cropperContainer {
    flex: 1;
    border-radius: 10px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(80% - 470px);
    min-width: 450px;
    min-height: 450px;
    height: calc(100vh - 300px);
    overflow: hidden;
    position: relative;
}







.createPublication-button-suivant {
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 15px;
    transition: all 0.3s;
}

.createPublication-button-suivant:hover {
    /* background-color: #4A35AB; */
}

.createPublication-button-confidentielle{
    
    background-color: #fff;
    color: red;
    padding: 10px 20px;
    border: 2px solid red;
    border-radius: 10px;
    width: 340px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 15px;
    transition: all 0.3s;
}


.createPublication-button-confidentielle:hover {
    
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: 2px solid red;
    border-radius: 10px;
    width: 340px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 15px;
    transition: all 0.3s;
}


.createPublication-button-precedant {
    background-color: #ffffff;
    color: #4A35AB;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 15px;
    border: 1px solid #4A35AB;
    transition: all 0.3s;
}

.createPublication-button-precedant:hover {
    color: white;
    background-color: #4A35AB;
    transition: all 0.3s;

}




.form-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}


.form-container-clients {
    padding: 20px;
    height: calc(100vh - 60px);
    overflow-y: auto;
    scrollbar-width: none;
}



.form-container-publications {
    height: calc(100vh - 60px);
    overflow-y: auto;
    scrollbar-width: none;
}

.form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.form-title-modal {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
}


.form-sous-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.form-description {
    max-width: 500px;
    font-size: 16px;
    margin-bottom: 20px;
}

.form-details {
    font-size: 18px;
    margin-left: 8px;
    color: rgb(70, 70, 70);
}

.form-description-orange{
    font-size: 16px;
    margin-bottom: 20px;
    color: rgb(211, 112, 0);
}

.form-input-left {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: left;
    cursor: text ;
}

.input-container-left-textArea {
    display: flex;
    justify-content: left;
    align-items: left;
    margin-bottom: 10px;
}

.form-textArea-left {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    min-height: 100px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: left;
    cursor: text ;
}

.form-textArea-left-organisation {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 850px;
    min-height: 100px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: left;
    cursor: text ;
}

.form-input-left-recherche {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 350px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: left;
    cursor: text ;
}

.form-reset-password-link{
    text-decoration: none;
    color: gray;
}

.form-button-suivant {
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    color: white;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 420px;
    width: 100%;
}

.form-button-suivant:hover {
    background-color: #4A35AB;
}


.form-button-suivant-organisation {
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    color: white;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 420px;
    width: 20%;
}



.checkbox-container {
    margin-bottom: 15px;
    max-width: 500px;
}

.checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label {
    display: flex;
    align-items: center; 
    position: relative;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    user-select: none;
    padding-left: 30px;
    margin-right: 15px;
}

.custom-checkbox {
    position: absolute;
    top: 50%; 
    left: 0;
    transform: translateY(-50%); 
    height: 17px;
    width: 17px;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: all 0.2s;
}

.checkbox-input:checked + .custom-checkbox {
    background-color: #4A35AB;
    border-color: #4A35AB;
}

.custom-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-input:checked + .custom-checkbox:after {
    display: block;
}

.custom-checkbox:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.link-cgu {
    color: #4A35AB; 
    text-decoration: none; 
}
  
.link-cgu:hover {
    text-decoration: underline;
}


.div-gestion-element{
    display: flex;
    align-items: center;
}

.form-button-delete {
    text-decoration: none;
    border: 2px solid #BB0000;
    background-color: #fff;
    color: #BB0000;
    padding: 10px ;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 200px;
    width: 100%;
    transition: background-color 0.3s ease;
}

.form-button-delete:hover {
    background-color: #BB0000;
    color: #fff;
    transition: background-color 0.3s ease;
}

.form-button-disable-enable {
    text-decoration: none;
    border: 2px solid #EC7100;
    background-color: #fff;
    color: #EC7100;
    padding: 10px ;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 200px;
    width: 100%;
}

.form-button-disable-enable:hover {
    background-color: #EC7100;
    color: #fff;
    transition: background-color 0.3s ease;
}



.style-container-input{
    display: 'flex';
    flex-direction: 'column';
}

.form-button-create-compte{
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #4A35AB;
    color: #4A35AB;
    padding: 10px ;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 420px;
    width: 100%;
    text-align: center;
}




.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}


.profile-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    max-width: 850px;
    width: 100%;
}

.profile-item-link {
    text-decoration: none;
}

.profile-item {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 20px;


    background: #F8FAFD;
    border-radius: 15px;
    padding: 15px;
    

    overflow-x: auto;
    transition: all 0.3s ease;

}


.profile-item:hover {
    background: #e6e6e6;
    transition: all 0.3s ease;
}

.profile-logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.profile-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}



.changeProfilPicture-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}


.changeProfilPicture-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.changeProfilPicture-description {
    font-size: 16px;
    margin-bottom: 20px;
}

.changeProfilPicture-fileInput {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.changeProfilPicture-fileInput-label {
    display: inline-block;
    padding: 10px 25px;
    border: 2px solid #4A35AB;
    border-radius: 20px;
    background-color: #fff;
    color: #4A35AB;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.changeProfilPicture-fileInput-label:hover {
    background-color: #4A35AB;
    color: white;
}

.changeProfilPicture-contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.changeProfilPicture-croppedImage {
    object-fit: cover;
    border-radius: 15px;
}


.changeProfilPicture-cropperContainer{
    object-fit: cover;
    max-width: 600px;
    /* max-height: 400px; */
    overflow: hidden;
    position: relative;
}



.parametrage-organisation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}


.parametrage-organisation-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 90%;
    width: 100%;
}

.parametrage-organisation-item-link {
    text-decoration: none;
}

.parametrage-organisation-item {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    min-width: 250px;
    /* border-bottom: 2px solid #ccc; */
    background-color: #F8FAFD;
    padding: 15px;
    border-radius: 15px;
    transition: background-color 0.3s ease;
}

.parametrage-organisation-item:hover {
    background-color: #cecece;
    transition: background-color 0.3s ease;
}

.titre-parametrage-organisation-text{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.sousTitre-parametrage-organisation-text{
    margin-left: 36px;
}


.status-toggle-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}


.status-toggle {
    padding: 10px 20px;
    font-size: 16px;
    border: 0px solid #fff;
    margin-right: 15px;
    border-bottom: 2px solid #d8d8d8;
    background-color: #fff;
    cursor: pointer;
    transition: border 0.2s ease, color 0.2s ease;
}

.status-toggle.active {
    border-bottom: 2px solid #4CAF50;
    color: black;
}

.status-toggle.inactive {
    border-bottom: 2px solid #f44336;
    color: black;
}

.status-toggle:hover {
    opacity: 0.8;
}





.form-button-create-link {
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    color: white;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 250px;
    width: 240px;
    margin-left: auto;
    text-align: center;
}



.form-button-create-link:hover {
    background-color: #4A35AB;
}






.list-element-organisation {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    padding: 0;
    margin: 0;
    list-style-type: none;
    justify-content: left;
}


.list-element-organisation-cpo {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 0;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 3px;
    list-style-type: none;
    justify-content: left;
}

.list-element-organisation-item {
    background-color: #eeeeee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    min-width: 450px;
    max-width: 450px;
    border-bottom: 0px solid gray;
    padding: 15px;
    box-shadow: 0 0 8px 3px rgba(0,0,0,0.09);
}

.list-element-organisation-details {
    padding-right: 50px;
}


.list-element-organisation-item-bulle-tag {
    display: flex;
    padding: 10px 15px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    text-align: center;
}


.publication-link{
    text-decoration: none;
}



.switch-container {
    display: flex;
    align-items: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bd00009f;
    transition: .4s;
    border-radius: 25px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #00bd09a8;
}

input:checked + .slider:before {
    transform: translateX(20px);
}

.remove-choice-button {
    background-color: #d80000;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.remove-choice-button:hover {
    background-color: #bd0000;
    transition: background-color 0.3s ease;
}

.remove-choice-button .icon {
    margin-right: 5px;
}

.add-choice-button{
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 422px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #9e9e9e;
    background-color: #fff;
    text-align: center;
    cursor: text ;
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
}

.add-choice-button:hover {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 422px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    background-color: #2fa700;
    border: 1px solid #2fa700;
    color: white;
    cursor: pointer ;
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
}



/* Container principal en flexbox */
.color-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.color-picker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(0,0,0,0.1);
}

.color-picker::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 50%;
}

.color-picker::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.color-picker::-moz-color-swatch {
    border: none;
    border-radius: 50%;
}


/* Aperçu du tag */
.tag-preview {
    padding: 5px 10px;
    border-radius: 10px;
    min-width: 100px;
    display: inline-block;
    text-align: center;
}

.tag-text {
    font-size: 14px;
}


.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    position: relative;
}

.error-box {
    text-align: center;
    z-index: 1;
}

.error-title {
    font-size: 6rem;
    margin: 0;
    color: #ff6b6b;
    font-weight: bold;
    animation: bounce 1.5s infinite;
}

.error-text {
    font-size: 1.5rem;
    color: #555;
    margin: 20px 0;
}

.back-home-btn {
    padding: 10px 20px;
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.back-home-btn:hover {
    background-color: #ff3b3b;
}




/*Visuel du bloc de chargement*/
.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    border-radius: 8px; 
}


.bubble {
    position: fixed;
    top: 20px;
    left: 50%;
    width: 70%;
    max-width: 400px;
    text-align: center;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    z-index: 1000;
    animation: fadeInOut 3s ease forwards;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(-20px);
    }
    10% {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-50%) translateY(-20px);
    }
}





/* Animation pour le titre "404" */
@keyframes bounce {
    0%, 20%, 50%, 70%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
}



.container-avis{
    background-color: #F8FAFD;
    padding: 15px;
    border-radius: 15px;
}


.container-avis-gestion
{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}



.avis-container {
    margin-left: 10px;
    margin-top: 25px;
    padding: 5px 15px;
    border-left: 3px solid transparent;
    border-image: linear-gradient(5deg, #FFA500, #8A2BE2);
    border-image-slice: 1; 
}

.bloc-principal{
    
    max-width: 900px;
    width: 100%;
}

.questions {
    display: flex; 
    flex-wrap: wrap; 
    gap: 20px; 
    align-items: stretch; 
}

.question-item {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    flex: 1; 
    min-width: 150px; 
    max-width: 200px; 
    height: 60px; 
    box-sizing: border-box; 
}


.description_avis_1,
.description_avis_2,
.description_avis_3,
.description_avis_4,
.description_avis_5 {
    margin-top: -1px;
    margin-bottom: 0px;
    background-color: #F44336; 
    color: #fff; 
    padding: 5px 10px;
    border-radius: 8px;
    width: 45px; 
    text-align: center; 
    box-sizing: border-box; 
}

.description_avis_5 {
    background-color: #4CAF50; 
}

.description_avis_4 {
    background-color: #8BC34A; 
}

.description_avis_3 {
    background-color: #FFC107; 
}

.description_avis_2 {
    background-color: #FF9800; 
}

.description_avis_1 {
   background-color: #F44336; 
}


.description_detail_avis_1,
.description_detail_avis_2,
.description_detail_avis_3,
.description_detail_avis_4,
.description_detail_avis_5 {
    margin-top: -1px;
    margin-bottom: 0px;
    /* background-color: #F44336;  */
    color: #fff; 
    /* padding: 5px 10px; */
    border-radius: 8px;
    width: 45px; 
    text-align: center; 
    box-sizing: border-box; 
}

.description_detail_avis_5 {
    color: #4CAF50; 
}

.description_detail_avis_4 {
    color: #8BC34A; 
}

.description_detail_avis_3 {
    color: #FFC107; 
}

.description_detail_avis_2 {
    color: #FF9800; 
}

.description_detail_avis_1 {
   color: #F44336; 
}

.commentaire {
    color: #5c5c5c;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 0px;
    font-style: italic; 
    border-top: 1px solid #ddd; 
    padding-top: 15px; 
}


.column-input-mode{
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 350px;  
    min-width: 168px;
}

.form-paiement {
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 350px;  
}

.select-option-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.select-option-image {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}



.form-title-paiement,
.card-number-element-paiement,
.card-number-element-paiement {
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    max-width: 400px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: left;
    cursor: text ;
}


.resumer-paiment {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 12px;
    max-width: 325px;
    min-width: 250px;  
}


.button-paiement{

}



.card-number-element-paiement {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 12px;
}

.expiry-cvc-container {
    display: flex;
    gap: 10px; 
    width: 100%;
}


.card-expiry-element-paiement,
.card-cvc-element-paiement {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 12px;
    
}

.button-paiement {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #3182ce;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-paiement:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}




.divPaiementPublication {

    background-color: #F8FAFD;
    padding: 15px;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 0px;

}

.divPaiementListe{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    padding: 0px;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 0px;

}

.listePaiementPublication {
    
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    background-color: #F8FAFD;
    padding: 10px;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.paiement-item-publication {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px;
    min-width: 360px;
    max-width: 360px;
   
    border: double 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

}
  

.paiement-button-publication {

    display: flex;
    justify-content: center; 
    align-items: center;

    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    border-radius: 15px;
    
    padding: 10px 16px;

    /*Obligatoire car lié au dégradé*/
    color: #ffffff;
    font-family: 'bold', sans-serif;
    font-size: 18px;

    margin-top: 15px;
    transition: all 0.3s ease;
    border: 2px solid white;

}


.paiement-button-publication:hover {
    border: 2px solid transparent;
    background: 
        linear-gradient(45deg, #FFA500, #8A2BE2),  /* Pour le texte */
        linear-gradient(white, white),              /* Pour le fond */
        linear-gradient(45deg, #FFA500, #8A2BE2);  /* Pour la bordure */
    background-clip: text, padding-box, border-box;
    -webkit-background-clip: text, padding-box, border-box;
    background-origin: padding-box, padding-box, border-box;
    color: transparent;

    /*Obligatoire car lié au dégradé*/
    font-family: 'bold', sans-serif;
    font-size: 18px;
}



.description-paiement{
    margin-top: 5px;
}

  
.paiement-info-right {
    display: flex;
    align-items: flex-start; 
    flex-shrink: 0;
}
  

.montant-paiement-publication {
    margin-left: 15px;
}


.organisation-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    padding: 0;
    list-style: none;
}
  
.organisation-list-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    max-width: 450px;
    min-width: 350px;
}
  
.organisation-info {
    display: flex;
    justify-content: space-between;
}
  
.organisation-info-left {
    flex: 1;
}
 
.organisation-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}
  
.organisation-description {
    color: #777;
    margin: 5px 0;
    max-width: 250px;
}
  
.organisation-info-right {
    flex-shrink: 0;
}
  
.organisation-amount {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}
  
.organisation-action {
    margin-top: 10px;
    text-align: right;
}
  
.organisation-button-paiement {
    background-color: #4A35AB;
    padding: 10px 16px;
    color: #fff;
    border-radius: 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-family: 'Regular', sans-serif;
    border: 0px solid white;
    font-size: 16px;
    justify-content: center; 
    margin-top: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
  
.organisation-button-paiement img {
    margin-left: 5px;
}

.organisation-button-paiement:hover {
    background-color: #372A8D; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}





  
.organisation-button-process-paiement {
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    padding: 10px 16px;
    color: #fff;
    border-radius: 12px;
    font-family: 'Regular', sans-serif;
    font-size: 16px;
    border: 0px solid white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-top: 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}





.organisation-button-process-paiement:disabled {

    background-color: #585858;

}
  

.hidden {
    opacity: 0; 
    pointer-events: none; 
    transition: opacity 0.1s ease;
}


.icon-option {
    width: 24px; 
    height: 24px;
    margin-left: 5px; 
    vertical-align: middle;
    transform: translateY(3px); 
}


.no-link-decoration
{
    text-decoration: none;
    color: #000000;
}


.options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    
}

.option {

    position: relative;
    background-color: #F8FAFD;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    max-width: 500px;
    border-bottom: 0px solid gray;
    padding: 15px;
    overflow: hidden;
    cursor: pointer;

}

.option::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e4e4e4;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}


.option:hover::before {
    opacity: 1;
}

.option:hover .sousTitre2-simple {
    /* color: #ffffff !important; */
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}

.option > * {
    position: relative;
    z-index: 2;
}

.option:hover {
    color: #ffffff !important;
}





.option-utilisateur {

    position: relative;
    /* background-color: #F8FAFD; */
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    max-width: 500px;
    border-bottom: 0px solid gray;
    padding: 10px; 
    overflow: hidden;
    cursor: pointer;

}

.option-utilisateur::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e4e4e4;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}


.option-utilisateur:hover::before {
    opacity: 1;
}

.option-utilisateur:hover .sousTitre2-simple {
    /* color: #ffffff !important; */
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}

.option-utilisateur > * {
    position: relative;
    z-index: 2;
}

.option-utilisateur:hover {
    color: #ffffff !important;
}




.arrow-icon {
    font-size: 20px; 
}

hr {
    border: 1px solid #cecece;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
}



.list-paiement-organisation {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 0;
    margin: 0;
    list-style-type: none;
    justify-content: left;
}


.list-paiement-organisation-item {
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 45%;
    max-width: 350px;
    border-bottom: 1px solid gray;
    padding: 10px;
}



.orange {
    color: #FF9800;
}
  
.vert {
    color: #4CAF50;
}

.status-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}
  
.dot-orange {
    background-color: orange;
}
  
.dot-vert {
    background-color: green;
}





/*Design page organisation*/




/* Détails organisation */

.content-pageOrganisation{
    padding: 30px;
}

.content-detailsOrganisation {
    display: flex;
    align-items: top;
    gap: 40px;
    min-height: 350px;
}

.title-section-pageOrganisation {
    flex: 1;
    font-family: Arial, sans-serif;
}


.certification {
    display: inline-block;
    margin: 0;
    padding: 0;
}




/* Gestion de l'affichage des images */
.images-section-pageOrganisation {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}

/* Styles pour chaque image */
.image-pageOrganisation_1, 
.image-pageOrganisation_2, 
.image-pageOrganisation_3 {
    width: 130px;
    height: auto;
    border: 1px solid;
    position: relative; 
}

/* Couleurs et coins arrondis pour les petites images */
.image-pageOrganisation_1 {
    border-radius: 15px;
    border: 0px solid rgb(26, 0, 175); 

    margin-top: 50px; 
    margin-left: -50px; 
    margin-right: 70px; 
}

.image-pageOrganisation_2 {
    border-radius: 25px;
    border: 0px solid blue; 

    margin-top: 180px; 
    margin-left: -50px; 
    margin-right: -120px; 
}

.image-pageOrganisation_3 {
    border-radius: 20px;
    border: 0px solid green; 

    margin-top: 15px; 
    margin-right: 20px; 

}

/* Grande image à droite */
.image-pageOrganisation_4 {
    border-radius: 30px;
    border: 0px solid red; 

    width: 340px;
    height: auto;
    
}

/* Assurez-vous que les petites images se placent de manière ordonnée */
.images-section-pageOrganisation > :nth-child(-n+3) {
    display: block;
    margin-bottom: 10px; 
}







/* Publication */



.container-publication-pageOrganisation {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%; 
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch;
}

.container-publication-pageOrganisation::-webkit-scrollbar {
    display: none;
}



.user-list-pageExperience {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
    gap: 70px; 
}



.user-list-pageOrganisation {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    list-style-type: none;
    gap: 70px; 
}

.user-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 0;
    list-style-type: none;
    justify-content: center;
}






/* Services */

.list-service-pageOrganisation-container {
    position: relative; 
    display: flex;
    justify-content: center;  
    align-items: center;
    width: 100%;  
}

.list-service-pageOrganisation {
    display: flex;
    gap: 50px; 
    overflow-x: auto;
    scroll-behavior: smooth;
    flex-grow: 1; 
}

.scroll-arrow {
    background-color: rgba(68, 68, 68, 0.5);
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    border-radius: 80px;
    transition: opacity 0.4s ease, transform 0.4s ease, background-color 0.4s ease;
}

.scroll-arrow:hover {
    background-color: rgba(68, 68, 68, 0.8);
    transition: opacity 0.4s ease, transform 0.4s ease, background-color 0.4s ease;
}

.left-arrow {
    left: -15px; 
}

.right-arrow {
    right: -15px;
}

.content-services-pageOrganisation {
    width: 100%;
    height: 250px;
    background-color: #E9E9E9;
    box-sizing: border-box;
    min-width: 350px;
    border-radius: 15px;
    padding: 15px;
    display: flex;               
    flex-direction: column; 
}

.title-section-service-pageOrganisation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
}

.price-button-pageOrganisation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
}

.list-service-pageOrganisation::-webkit-scrollbar {
    display: none;
}







/* A propos */


.content-apropos-pageOrganisation {

}
  
.row-apropos-pageOrganisation {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

/* Colonne de gauche */
.left-column-apropos-pageOrganisation {
    width: 45%;
    max-width: 550px;
}
  


/* Colonne de droite */
.right-column-apropos-pageOrganisation {
    margin-left: auto;
    width: 55%;
}
  
.phrase-design-apropos-pageOrganisation
{

    display: flex;
    flex-direction: column; 
    align-items: left; 
    justify-content: center; 
    text-align: center;
    min-width: 42%;
    max-width: 42%;
    
    min-height: 250px;
    max-height: 250px;
    
    border-radius: 20px;

    padding: 20px;

    background-image: linear-gradient(45deg, rgba(255, 165, 0, 0.8), rgba(138, 43, 226, 0.8));

}

  
.image1-apropos-pageOrganisation,
.image2-apropos-pageOrganisation {

    min-width: 290px;
    max-width: 290px;
    min-height: 290px;
    max-height: 290px;
    border: 0px solid red;
    border-radius: 15px;

}
  
/* Flexbox pour garantir que l'image et la phrase se positionnent côte à côte */
.phrase1-image-apropos-pageOrganisation,
.phrase2-image-apropos-pageOrganisation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
    






/* Questions réponses */

.content-questionReponse-pageOrganisation {
    
    display: inline-block;
    vertical-align: top;

    width: 45%; 
    min-width: 350px;
    max-width: 650px;

    margin-bottom: 45px;
    margin-right: 50px;

    box-sizing: border-box;
    border-bottom: 2px solid transparent; 
    border-image: linear-gradient(45deg, #FFA500, #8A2BE2) 1;

}
  
.question-container-questionReponse-pageOrganisation {
    display: flex;
    align-items: center;
    justify-content: space-between; 
}

.title-section-questionReponse-pageOrganisation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.toggle-response-checkbox {
    display: none; 
}
  
.toggle-response-questionReponse-pageOrganisation {
    background: none;
    border: none;
    cursor: pointer;
}


.response-section-questionReponse-pageOrganisation {
    visibility: hidden; 
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    padding: 5px;
    transform: translateY(-10px); 
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s, max-height 0.3s ease, margin-bottom 0.3s ease;
}

.toggle-response-checkbox:checked ~ .response-section-questionReponse-pageOrganisation {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
    margin-bottom: 10px;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0s, max-height 0.3s ease, margin-bottom 0.3s ease;
}

  
.no-style-link {
    text-decoration: none;
    color: inherit; 
    cursor: pointer; 
}


.contact-information-organisation{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
  

.subscribe {
    all: unset; 
    min-width: 150px;
    padding: 10px 20px; 
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    background-size: 150% 150%;
    color: white;
    border: none;
    border-radius: 15px; 
    cursor: pointer; 
    transition: background-size 0.5s ease, background-position 0.5s ease;
}

.subscribe:hover {
    background-position: right center;
    transition: background-size 0.5s ease, background-position 0.5s ease;
}

.unsubscribe {
    all: unset; 
    min-width: 150px;
    padding: 10px 20px; 
    text-align: center;
    background-image: linear-gradient(35deg, #FFA500, #8A2BE2); 
    background-size: 150% 150%;
    color: white;
    border: none;
    border-radius: 15px; 
    cursor: pointer; 
    transition: background-size 0.5s ease, background-position 0.5s ease;
}

.unsubscribe:hover {
    background-position: right center;
    transition: background-size 0.5s ease, background-position 0.5s ease;
}



.contact-raccourci-button {
    all: unset;
    position: relative;
    display: inline-block;
    min-width: 150px;
    padding: 10px 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    cursor: pointer;
    z-index: 1;
    overflow: hidden; 
}

.contact-raccourci-button::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    background: linear-gradient(45deg, #FFA500, #8A2BE2); 
    background-size: 100% 100%; 
    border-radius: inherit; 
    padding: 2px; 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0); 
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude;
    transition: background-size 0.5s ease;
}

.contact-raccourci-button:hover::before {
    background-size: 200% 200%;
}



.container-infos-bulle {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}


.container-avantages-organisation {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
  
.column-left-avantages-organisation {
    flex: 1;
    min-width: 300px;
    box-sizing: border-box;
    margin-right: 70px;
    padding: 10px 0px;
}
  


.column-right-avantages-organisation {
    display: flex;
}


.column-right-avantages-organisation-details {
    display: flex;

    flex: 1;
    min-width: 450px;

    align-items: center;
    justify-content: center;
    
    padding: 10px;
    margin-right: 20px;

    position: relative;
}


.column-right-avantages-organisation-details::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px; 
    padding: 2px; 
    background: linear-gradient(45deg, #FFA500, #8A2BE2) ; 
    mask: 
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude; 
}

.two-line-input-div {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    max-width: 970px;
}

.block-input-two-line{
    width: 45%;
}

.two-line-input-div-textarea {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.block-input-two-line-textarea{
    width: 100%;
    max-width: 62%;
}



.div-title-apropos{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
}

.div-title-apropos-selection{
    display: flex;
    justify-content: center; 
    align-items: flex-start;
    height: auto; 
    gap: 80px;
}

.div-title-apropos-selection-details{
    display: flex;
    justify-content: center; 
    align-items: flex-start;
    height: auto; 
    gap: 80px;
    animation: fadeInUp 1s ease forwards;

}




.div-contains-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 150px;
    background-color: #F8FAFD;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.div-contains-title:hover{
    background-color: #ebebeb;
    transition: background-color 0.3s ease;
}



.div-contains-title-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    height: auto; 
    min-width: 350px;
    max-width: 350px;
    padding-top: 5px;

}

.div-contains-apropos-explication-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    height: auto; 
    max-width: 500px;
    padding-top: 5px;
    
   
}

.separator-line {
    
    max-width: 940px;
    height: 2px;
    background-color: #c2c2c2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 50px;
    animation: fadeInUp 1s ease forwards;
}



/*Dashboard Organisation*/

.div-classique{

    background: #F8FAFD;
    border-radius: 15px;
    padding: 25px;
    border: 0px solid #e5e7eb;
    overflow-x: auto;

}




.div-classique-limiter{

    background: #F8FAFD;
    border-radius: 15px;
    padding: 10px;
    border: 0px solid #e5e7eb;
    max-width: 400px;

}

.div-classique-tableau{

    border: 2px solid #F8FAFD;
    border-radius: 15px;
    overflow-x: auto;

}

.enTete-Tableau{
    background-color: #F8FAFD;
}

.empty-message-tableau{
    margin-left: 15px;   
    margin-top: 10px;
}

.clickable-row {
    cursor: pointer;
}
  
.clickable-row:hover {
    background-color: #f0f0f0;
}
  
.table-row:not(.clickable-row) {
    cursor: default;
}

/* Stats Grid */
.stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-bottom: 24px;
}
  
.stat-card {
    background: #F8FAFD;
    border-radius: 15px;
    padding: 25px;
    border: 0px solid #e5e7eb;
}
  
  
.stat-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.stat-link {
    text-decoration: none;
}
  
 
  

.data-table {
    width: 100%;
    border-collapse: collapse;
}

  
.data-table th,
.data-table td {
    padding: 16px 16px;
    text-align: left;
    
}
  
.data-table th {
    border-bottom: 2px solid #F8FAFD;
}

.data-table td {
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 2px solid #F8FAFD;
    min-width: 200px;
}
  
  
.data-table tbody tr:hover {
    background: #f9fafb;
}
  
.status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    
}
  
.status-badge.paye {
    background: rgba(22, 101, 52, 0.3);
    color: #166534;
}
  
.status-badge.en-cours {
    background: rgba(189, 75, 0, 0.3);
    color: #bd4b00;
}
  
.status-badge.en-attente{
    background: rgba(189, 75, 0, 0.3);
    color: #bd4b00;
}


.likes-user-info-details {
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
}

.likes-publication-info {
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
}


.btn-suivant-pagination{ 
    all: unset;
    margin-top: -6px;
    margin-right: 10px;
   
}

.btn-suivant-pagination:disabled{
    all: unset;
    margin-top: -6px;
    margin-right: 10px;
}



.btn-page-pagination{
    all: unset;
    background-color: #777777;
    color: white;
    font-weight: 600;
    font-size: 18px;
    height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;

}

.btn-page-pagination.active{
    all: unset;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    color: white;
    font-weight: 600;
    font-size: 18px;
    height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 10px;
}



.btn-precedant-pagination{
    all: unset;
    margin-top: -6px;
    margin-left: 10px;
}

.btn-precedant-pagination:disabled{
    all: unset;
    margin-top: -6px;
    margin-left: 10px;
}




.status-toggle-container-option-dropdown {
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
}
  
.status-toggle-option-dropdown {
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 8px;
    border: 1px solid #ddd;
    cursor: pointer;
}
  
.status-toggle-active-option-dropdown {
    background-color: #4f46e5;
    color: white;
}
  
.status-toggle-inactive-option-dropdown {
    background-color: #f3f4f6;
}
  
.actions-container-option-dropdown {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative; 
}
  
.options-menu-container-option-dropdown {
    position: relative;
    display: inline-block; 
}
  
.options-button-option-dropdown {
    width: 32px;
    height: 32px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.options-dots-option-dropdown {
    width: 4px;
    height: 4px;
    background: #666;
    border-radius: 50%;
    position: relative;
}
  
.options-dots-option-dropdown::before,
.options-dots-option-dropdown::after {
    content: '';
    width: 4px;
    height: 4px;
    background: #666;
    border-radius: 50%;
    position: absolute;
    left: 0;
}
  
.options-dots-option-dropdown::before {
    top: -8px;
}
  
.options-dots-option-dropdown::after {
    bottom: -8px;
}
  
.dropdown-menu-option-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    min-width: 150px;
    box-shadow: 0 0px 10px rgba(0,0,0,0.2);
    z-index: 1000;
    margin-top: 10px;
}
  
.dropdown-menu-item-option-dropdown {
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    display: block;
  }
  
.dropdown-menu-item-option-dropdown:hover {
    background: #f5f5f5;
}
  
.dropdown-menu-item-delete-option-dropdown {
    color: #dc2626;
}






/* Gestion du modal de préférences */
.modal-preferences-floating-btn {
    
    position: fixed;
    
    bottom: 20px;
    right: 20px;

    width: 60px;
    height: 60px;

    border-radius: 15px;
    box-shadow: 0 0px 15px -3px rgba(0, 0, 0, 0.2);
    padding: 10px 14px 7px 14px;
    

    border: double 2px transparent;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    cursor: pointer;
    
    transition: all 0.3s ease;
    z-index: 40;
}

.modal-preferences-floating-btn:hover {
    
    background-color: #e0e0e0;
    
}


.preference-icone {
    width: 28px;
    height: 28px;
}

.modal-preferences-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    z-index: 50;
    background: rgba(0,0,0,0.5);
    animation: fadeIn 0.2s ease-in-out forwards;
}


.modal-preferences-content {
    background: white;
    height: 96%;
    min-width: 550px;
    width: 25%;
    margin-right: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    animation: slideIn 0.3s ease-in-out forwards;

}

.modal-preferences-header {
    padding: 15px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-preferences-close{
    border: double 0px transparent;
    background-color: white;
    cursor: pointer;
    padding: 0px;
    height: 30px;
    width: 30px;
    
    transition: transform 0.2s ease;

}

.modal-preferences-overlay.closing {
    animation: fadeOut 0.3s ease-in-out forwards;
}

.modal-preferences-overlay.closing .modal-preferences-content {
    animation: slideOut 0.3s ease-in-out forwards;
}

.fermer-icone{
    height: 30px;
    width: 30px;
}





.famille-preference {
 margin-bottom: 25px;
}




.modal-preferences-body {
    flex: 1;
    position: relative;
}

.modal-preferences-scroll {
    position: absolute;
    inset: 0;
    padding: 1rem;
    overflow-y: auto;
}




.categories-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; 
}
  
.categorie-item {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 20px;  
    cursor: pointer;
    transition: all 0.2s ease;
    /* background-color: #f3f4f6; */
    border: 2px solid #b9b9b9;
    white-space: nowrap;
}
  
.categorie-item:hover {
    border-radius: 20px;
    border: 2px solid  transparent;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    
    transition: all 0.2s ease;
}
  
.categorie-selected {

    border: 2px solid white;
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    
    transition: all 0.2s ease;
}
  
.categorie-selected:hover {
    /* background-color: #dbeafe; */
    border: 2px solid white;
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    
    transition: all 0.2s ease;
}




.modal-preferences-footer {

    display: flex;
    justify-content: center;


    padding: 1rem;
    border-top: 1px solid #e5e7eb;
    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}


.modal-preferences-save-button {
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    color: white;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 380px;
    width: 100%;

    transition: all 0.3s ease;
}

.modal-preferences-save-button:hover {
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2); 
    color: white;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 380px;
    width: 100%;

    
    box-shadow: 0 0px 15px -3px rgba(0, 0, 0, 0.2);
}


.modal-preferences-connexion {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;

    background: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    padding: 15px;
}



/*Modal menu utilisateur*/
.modal-menu-utilisateur-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    z-index: 50;
    background: rgba(0,0,0,0.5);
    animation: fadeIn 0.2s ease-in-out forwards;
}


.modal-menu-utilisateur-floating-btn {
    
    position: fixed;
    
    bottom: 20px;
    right: 20px;

    border-radius: 15px;
    box-shadow: 0 0px 15px -3px rgba(0, 0, 0, 0.2);
    padding: 10px 14px 7px 14px;
    

    border: double 2px transparent;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    cursor: pointer;
    
    transition: all 0.3s ease;
    z-index: 40;
}

.modal-menu-utilisateur-floating-btn:hover {
    
    position: fixed;

    box-shadow: 0 0px 15px -3px rgba(0, 0, 0, 0.3);
    padding: 11px 15px 8px 15px;

    border: double 2px transparent;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    z-index: 40;
}


.menu-utilisateur-icone {
    width: 28px;
    height: 28px;
}


.modal-menu-utilisateur-content {
    background: white;
    height: 96%;
    min-width: 550px;
    width: 25%;
    margin-right: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    animation: slideIn 0.3s ease-in-out forwards;

}

.modal-menu-utilisateur-header {
    padding: 15px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-menu-utilisateur-close{
    border: double 0px transparent;
    background-color: white;
    cursor: pointer;
    padding: 0px;
    height: 30px;
    width: 30px;
    
    transition: transform 0.2s ease;

}

.modal-menu-utilisateur-overlay.closing {
    animation: fadeOut 0.3s ease-in-out forwards;
}

.modal-menu-utilisateur-overlay.closing .modal-menu-utilisateur-content {
    animation: slideOut 0.3s ease-in-out forwards;
}

.fermer-icone{
    height: 30px;
    width: 30px;
}





.famille-preference {
 margin-bottom: 25px;
}




.modal-menu-utilisateur-body {
    flex: 1;
    position: relative;
}

.modal-menu-utilisateur-scroll {
    position: absolute;
    inset: 0;
    padding: 1rem;
    overflow-y: auto;
}


.header-mobile {
    display: none;
}



/* Historique e-mail */
.status-error {
    background-color: #ff4444;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-warning {
    background-color: #ff9900;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-success {
    background-color: #00990d;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-clicked {
    background-color: #0400da;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-opened {
    background-color: #0400da;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-proxy {
    background-color: #5588ff;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-sent {
    background-color: #666666;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.status-unknown {
    background-color: #999999;
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}




@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}


@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes slideOut {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
}




.limitation_abonnement_organisation_system{
    color: white;
    background-color: rgba(167, 0, 0, 0.623); 
    max-width: 520px;
    margin-top: 25px;
    padding: 15px;
    border-radius: 15px;
}



.form-button-suivant-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}



/* Gestion du container d'action */
.action-bar {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
}
  
.action-bar-search {
    position: relative;
    flex: 1;
    display: flex;
}
  
.action-bar-input {
    width: 100%;
    padding: 13px 40px 10px 40px;
    border: 0px solid #ccc;
    color: #474747;
    border-radius: 15px;
    font-size: 16px;
    outline: none;
    max-width: 250px;
    background-color: #EDEFF2;
}

.action-bar-search img {
    position: absolute;
    left: 14px;
    top: 54%;
    transform: translateY(-50%);
    z-index: 1;
}
  
.action-bar-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.action-bar-button {
    text-decoration: none;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    color: white;
    padding: 10px ;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    max-width: 250px;
    width: 240px;
    margin-left: auto;
    text-align: center;
}
  
.text-inputContent {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #474747;
}

.placeholder-inputPlaceholder::placeholder {
    font-family: 'Regular', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #777777;
}




/* A MODIFIER OU SUPPRIMER */
@media (max-width: 1024px) {
    .stats-grid {
      grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 640px) {
    .stats-grid {
      grid-template-columns: 1fr;
    }
    
    .dashboard {
      padding: 16px;
    }
    
    .data-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    
    .stat-card {
      padding: 12px;
    }
}
/* A MODIFIER OU SUPPRIMER */






/* PC */
@media (min-width: 1024px) {
    .publication-container {
        display: flex;
        gap: 20px;
    }

    .left-column {
        flex: 0 0 40%;
        max-width: 450px;
        display: flex;
        flex-direction: column;
    }

    .right-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    }

    .detailsPublicationSpace {
        height: 50px;
    }

}









@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px); 
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}






/* Bloc particutier*/
@media (max-width: 1350px) {

    .content-pageOrganisation{
        padding: 10px;
    }

    .content-detailsOrganisation {
        min-height: 120px;
    }

    .images-section-pageOrganisation{
        display: none;
    }


    /* A propos */
    .left-column-apropos-pageOrganisation {
        width: 100%;
    }
      

    .row-apropos-pageOrganisation {

        display: flex;
        flex-direction: column; 
        align-items: left; 
        justify-content: center; 
        text-align: center;
        
    }


    .right-column-apropos-pageOrganisation {
        
        margin-top: 30px;
        width: 100%;
    }

    .phrase1-image-apropos-pageOrganisation,
    .phrase2-image-apropos-pageOrganisation {
        display: flex;
        flex-direction: column; 
        padding: 15px;
    }

    .phrase-design-apropos-pageOrganisation
    {

        min-width: 100%;
        max-width: 100%;
        
    }
      

    .image1-apropos-pageOrganisation,
    .image2-apropos-pageOrganisation {

       display: none;

    }

    .row-apropos-pageOrganisation {
        gap: 0px;
    }

    .parametrage-organisation-links {
        grid-template-columns: repeat(2, 1fr);
    }
    

}




/* Mobile */
@media (max-width: 1024px) {


    .nav-center {
        display: none;
    }

    .nav-right{
        display: none;
    }

    .menu-toggle {
        display: block;
    }

    .logo {
        flex: 1;
        text-align: center;
    }

    .menu-mobile {
        display: block;
    }

    .list-item-details-publication {
        width: 100%;
        max-width: 450px;
        min-width: 350px;
    }

    .list-details-publication {
        width: 100%;
        max-width: 450px;
        min-width: 350px;
    }

    .profile-links {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        max-width: 800px;
        width: 100%;
    }

    .gestion-prestation-container {
        max-width: 450px;
    }

    .fields-cpo-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .list-paiement-organisation-item {
        background-color: #fff;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        width: 80%;
        min-width: 80%;
        max-width: 450px;
        border-bottom: 1px solid gray;
        padding: 10px;
    }

    .right-column {

        margin-top: 15px;

    }

    /* Services*/
    .content-services-pageOrganisation {
      
        min-width: 250px;
        border-radius: 15px;
      
    }

    /* Publication */
    .user-list-pageOrganisation {
        gap: 20px; 
    }

    /* Questions réponses */

    .content-questionReponse-pageOrganisation {
    
        display: block;
    
    }


    .span1 {
        display: block;
        height: 50px
    }

    .span2 {
        display: block;
        height: 30px
    }

    .span3 {
        display: block;
        height: 10px
    }

    .span4 {
        display: block;
        height: 130px
    }


    .contact-information-organisation{
        gap: 15px;
    }

    .subscribe {
        min-width: 120px;
    }

    .unsubscribe {
        min-width: 120px;
    }
    
    .contact-raccourci-button {
        min-width: 120px;
    }

    .column-right-avantages-organisation {
        display: flex;
        flex-wrap: wrap;
    }

    .column-right-avantages-organisation-details {
        margin-bottom: 20px;
        padding: 10px 20px;
        min-width: 300px;
        margin-right: 20px;
    }


    .column-left-avantages-organisation{
        margin-right: 0px;
        margin-bottom: 15px;
        max-width: 350px;
    }

    .two-line-input-div {
        width: 100%;
    }

    .block-input-two-line{
        width: 100%;
    }
    
    .block-input-two-line-textarea{
        width: 100%;
        max-width: 400px;
    }

    .container-contact-organisation{
        max-width: 400px;
    }

    .form-textArea-left-organisation {
        width: 100%;
        max-width: 94%;
    
    }

    .form-button-suivant-organisation {
        width: 100%;
    }

    .div-title-apropos-selection{
        flex-direction: column;
        gap: 20px;
    }
    .div-title-apropos-selection-details{
        flex-direction: column;
        gap: 20px;
    }

    .div-contains-title{

        width: 100%;

    }

    .div-contains-title-details{
        min-width: 80%;
    }

    .div-participant-prestataire {
        /* border: 1px solid red; */
        flex-direction: column;
    }
    
    .participant {
        background-color: #F8FAFD;
        max-width: 450px;
        min-width: 300px;
        margin-left: 0px;
    }
    
    .prestataire {
        background-color: #F8FAFD;
        max-width: 450px;
        min-width: 300px;
        margin-right: 0px;
    }


    .descriptionConfidentiel {
        max-width: 430px;
        min-width: 250px;
    }

    .div-gestion-avis{
        display: flex;
        flex-wrap: wrap;
        gap: 0px;
        margin-top: 15px;
    
    }


    .button-avis-gestion-update {
    
        display: flex;
        align-items: center;
        gap: 10px;
    
        border: 1px solid #8A2BE2; 
        color: #8A2BE2;
        border-radius: 10px;
        cursor: pointer; 
        margin-top: 5px;
        padding: 8px 15px;
        transition: background-color 0.3s ease;
        
    }

    

    .fieldset-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .fieldset-section-parametrage {
        grid-template-columns: repeat(1, 1fr);
    }
    
    
    .listePaiementPublication{
        margin-left: 0px;
        margin-top: 0px;
        padding-left: 0px;
    }

    .paiement-item-publication {
        min-width: 92%;
    }

    .div-liste-participant-modal {
        justify-content: flex-start;
    }
    
    .modal-preferences-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
 

    .modal-preferences-content {
        width: 92%;
        height: 95%;
        min-width: 300px;
        border-radius: 15px;
        margin-right: 0px;
      
    }

    .modal-preferences-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
      

    .modal-menu-utilisateur-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
 

    .modal-menu-utilisateur-content {
        width: 92%;
        height: 95%;
        min-width: 300px;
        border-radius: 15px;
        margin-right: 0px;
      
    }

    .modal-menu-utilisateur-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }



    /* Responsive du système de gestion de l'organisation */
    .organisation-item {
        position: relative;
        background-color: #F8FAFD;
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        min-width: 85%;
        max-width: 85%;
        border-bottom: 0px solid gray;
        padding: 20px;
        overflow: hidden;
        cursor: pointer;
    }


    .sousTitre2-simple-left {
        font-family: 'Bold', sans-serif;
        font-size: 20px;
        margin: 0;
        padding: 0;
        text-align: left;
        color: #474747;
    
    }

    .sousTitre2-simple {
        font-family: 'Bold', sans-serif;
        font-size: 20px;
        margin: 0;
        padding: 0;
        text-align: center;
        color: #474747;
    
    }

    .organisation-details {
        padding-left: 10px;
        padding-right: 10px;
    }

    .menuOrganisation-sidebar{
        display: none;
        width: 0px;

    }

        
    .menuOrganisation-content {
        margin-left: 0px;
        padding: 10px;
        margin-bottom: 60px;
    }

    .bottom-nav-menu-organisation {
        display: flex;
     
    }

    .nav-item-menu-organisation {
        display: flex;
    }

    .nav-plus-menu-organisation {
        display: flex;
    }


    .parametrage-organisation-links {
        grid-template-columns: repeat(1, 1fr);
        max-width: 100%;
    }


    .list-element-organisation-item {
        background-color: #eeeeee;
        border-radius: 10px;
        display: flex;
        align-items: center;
        min-width: 200px;
        max-width: 450px;
        border-bottom: 0px solid gray;
        padding: 10px;
        box-shadow: 0 0 8px 3px rgba(0,0,0,0.09);
    }

    .createPublication-croppedImage {
        display: none;
        min-width: 250px;
        min-height: 250px;
        max-width: 250px;
        max-height: 250px;
        object-fit: cover;
        border-radius: 15px;
    }



    
    .createPublication-cropperContainer {

        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }

    
    .createPublication-buttonContainer{
        display: flex;
    }


    .createPublication-button-suivant {
        margin-right: 0px;
    }






    /*Gestion des tableau*/

    .div-classique{

        background: #F8FAFD;
        border-radius: 15px;
        padding: 15px;
        border: 0px solid #e5e7eb;
        overflow-x: auto;
    
    }
    
    .data-table thead {
        display: none;
    }

    .data-table tbody,
    .data-table tr,
    .data-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin-left: 0;
        padding-right: 0;
        border: 0px solid #e5e7eb;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .data-table tr {
        margin-bottom: 1rem;

        border: double 2px transparent;
        border-radius: 10px;
        background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }


    .data-table tbody tr:hover {
        border: double 2px transparent;
        border-radius: 10px;
        background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
        background-origin: border-box;
        background-clip: padding-box, border-box;
    
    }
      

    .data-table td {
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        min-width: unset;
        border-bottom: 0px solid #dbdbdb;
    }

    /* Affichage des titres au-dessus */
    .data-table td::before {
        content: attr(data-label);
        font-weight: 600;
        color: #474747;
        margin-bottom: 8px;
        font-family: inherit;
        font-size: inherit;
    }

    .data-table td .likes-user-info-details,
    .data-table td .likes-publication-info {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 5px;
        margin-left: 10px;
    }

    .data-table td .status-badge {
        width: fit-content;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 10px;
    }

    .data-table td .description-simple {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 10px;
    }

    
    .header-mobile {
        display: block;
    }


    .top-navbar-container-organisation{
        display: none;
    }


    .action-bar-button {
        background-image: none;
        background-color: transparent;
        width: auto;
        padding: 0;
        margin-left: 16px;
    }
      
    
    
    .data-table th {
        border-bottom: 0px solid #F8FAFD;
    }
    
    .data-table td {
        margin-bottom: 10px;
        margin-top: 10px;
        border-bottom: 0px solid #F8FAFD;
    }

    .div-classique-tableau{

        border: 0px solid #F8FAFD;
        border-radius: 0px;
        overflow-x: auto;
    
    }
    
    .empty-message-tableau{
        margin-left: 15px;   
        margin-top: 0px;
    }

    

}



/* Gestion des image de publication avec taille inférieur */
@media (max-width: 450px) {

    .image-publication {
        max-width: 100%;
        max-height: 450px;
        min-width: 350px;
        min-height: 350px;
        display: block;
        border-radius: 15px;
    }

    .organisation-image-publication{
        max-width: 100%;
        max-height: 450px;
        min-width: 350px;
        min-height: 350px;
        display: block;
        border-radius: 15px;
    }

    .image-publication-details {
        max-width: 100%;
        max-height: 450px;
        min-width: 350px;
        min-height: 350px;
        display: block;
        border-radius: 15px;
    }

    .user-info {
        min-width: 100%;
    }

    .user-info-details {
        min-width: 100%;
    }

}


@media (max-width: 350px) {

    .image-publication {
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 250px;
        display: block;
        border-radius: 15px;
    }

    .organisation-image-publication{
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 250px;
        display: block;
        border-radius: 15px;
    }

    .image-publication-details {
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 250px;
        display: block;
        border-radius: 15px;
    }

    .user-info {
        min-width: 100%;
    }

    .user-info-details {
        min-width: 100%;
    }

}

@media (max-width: 250px) {

    .image-publication {
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 150px;
        display: block;
        border-radius: 15px;
    }

    .organisation-image-publication{
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 150px;
        display: block;
        border-radius: 15px;
    }

    .image-publication-details {
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 150px;
        display: block;
        border-radius: 15px;
    }

    .user-info {
        min-width: 100%;
    }

    .user-info-details {
        min-width: 100%;
    }

}

@media (max-width: 150px) {

    .image-publication {
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 50px;
        display: block;
        border-radius: 15px;
    }

    .organisation-image-publication{
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 50px;
        display: block;
        border-radius: 15px;
    }

    .image-publication-details {
        max-width: 100%;
        max-height: 450px;
        min-width: 100%;
        min-height: 50px;
        display: block;
        border-radius: 15px;
    }

    .user-info {
        min-width: 100%;
    }

    .user-info-details {
        min-width: 100%;
    }

}









/* #### A supprimer code: G55E52E */

.logo-saf{

    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    height: auto;
    display: block;

} 


.titre-saf {

    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 5px;

}

.description-simple-saf{

    font-family: 'Regular', sans-serif;
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
    padding: 0;
    max-width: 450px;
    text-align: left;
    color: #474742;
    white-space: pre-line;
       
}

.description-simple-saf-justifier{

    font-family: 'Regular', sans-serif;
    font-size: 18px;
    margin: 0;
    text-align: justify;
    line-height: 1.6;
    padding: 0;
    max-width: 450px;
    color: #474742;
    white-space: pre-line;
       
}

.div-oeuvre-saf {
    display: flex;
    gap: 15px;
}

.element-out-div-oeuvre-saf{
    background-color: #f1f1f1;
    width: 96,5%;
    padding: 8px;
    border-radius: 10px;
}

.element-oeuvre-saf{

    background-color: #f1f1f1;
    width: 50%;
    padding: 8px;
    border-radius: 10px;

}


.contact-bureau-vente-saf{

    display: flex;
    justify-content: center;
    padding: 8px;
   
    border: double 2px transparent;
    border-radius: 12px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

}


.consulter-page-saf{

    display: flex;
    justify-content: center;
    padding: 8px;
    max-width: 400px;
    width: 95%;
   
    border: double 2px transparent;
    border-radius: 12px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

}




.div-artiste-base-info-saf {
    display: flex;
    gap: 15px;
}

.element-artiste-base-info-saf-statut-flex-instagram{
    display: flex;
    background-color: #f1f1f1;
    padding: 8px 15px 8px 15px;
    border-radius: 10px;
    width: 50%;
    cursor: pointer;

    border: double 2px transparent;
    border-radius: 12px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #FFA500, #8A2BE2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

}


.element-artiste-base-info-saf-statut-flex-facebook{
    display: flex;
    background-color: #f1f1f1;
    padding: 8px 15px 8px 15px;
    border-radius: 10px;
    width: 50%;
    cursor: pointer;

    border: double 2px transparent;
    border-radius: 12px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #1877f2, #1877f2);
    background-origin: border-box;
    background-clip: padding-box, border-box;

}




.element-artiste-base-info-saf-statut{

    background-color: #f1f1f1;
    padding: 8px 15px 8px 15px;
    border-radius: 10px;
    width: 50%;

}


.element-artiste-base-info-saf-statut-nocolor{

    background-color: #ffffff;
    padding: 8px 15px 8px 15px;
    border-radius: 10px;
    width: 50%;

}

.element-artiste-base-info-saf-section{

    background-color: #f1f1f1;
    padding: 8px 15px 8px 15px;
    border-radius: 10px;
    width: 50%;

}

.logo-medaille{

    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    height: auto;
    display: block;

} 


.modal-saf {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 90%;
    margin-left: 15px;
    margin-right: 15px;
}


.sousTitre2-simple-degrade-left-20-saf {
    all: unset;
    font-family: 'Bold', sans-serif;
    font-size: 20px;
    margin: 0;
    margin-left: 10px;
    padding: 0;
    text-align: left;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}


.Titre-degrade-center-saf {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 28px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: inline;
}


.title-center-saf {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sousTitre2-simple-center-saf {
    font-family: 'Bold', sans-serif;
    font-size: 22px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #474747;
    max-width: 800px;

}


.sousTitre2-simple-degrade-left-saf {
    all: unset;
    font-family: 'TitanOneRegular', sans-serif;
    font-size: 25px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-image: linear-gradient(45deg, #FFA500, #8A2BE2);
    -webkit-background-clip: text;
    color: transparent; 
    display: block; 
}



.icon-question-saf{
    width: 24px; 
    height: 24px;
    margin-top: 25px; 
    transform: translateY(3px); 
}


/* #### A supprimer code: G55E52E */

